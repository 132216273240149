import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack, Typography } from '@mui/material';
import { PATH_AUTH } from '#/routes/paths';
import Iconify from '#/components/shared/ui/Iconify';
import Image from '#/components/shared/ui/Image';
import Page from '#/components/shared/ui/Page';
import VerifyCodeForm from '../../components/pages/VerifyCode/VerifyCodeForm';

export default function VerifyCodePage() {
  return (
    <Stack
      sx={{
        py: 12,
        maxWidth: 400,
        textAlign: 'center',
        m: 'auto',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      <Page title="Verify Code">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            sx={{ my: { mb: 5, sm: 10, height: 100, maxWidth: 100 } }}
            src="/assets/icons/verify.svg"
          />
        </div>

        <Typography variant="h3" paragraph>
          Please check your email!
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          We have emailed a 6-digit confirmation code to acb@domain, please
          enter the code in below box to verify your email.
        </Typography>

        <VerifyCodeForm />

        <Typography variant="body2" sx={{ my: 3 }}>
          Don’t have a code? &nbsp; Resend Code
        </Typography>

        <Link
          component={RouterLink}
          to={PATH_AUTH.login}
          color="inherit"
          underline="none"
          sx={{
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
          }}
        >
          <Iconify icon="eva:chevron-left-fill" width={16} />
          Return to sign in
        </Link>
      </Page>
    </Stack>
  );
}
